<template>
  <div>
    <title-bar :title="$t('navigations.sms')"/>
    <div v-if="rows" class="custom-table">
      <table-actions
        :actions="['search', 'perPage']"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:templateName="data">
          {{ $helper.getEnumTranslation("sms_template_name", data.value.templateName, $i18n.locale) }}
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }}, {{ $helper.formatTime(data.value.createdAt) }}
        </template>
        <template v-slot:phone="data">
          {{ data.value.recipient.telephone }}
        </template>
        <template v-slot:status="data">
          <b-badge :variant="statusClass(data.value.status)">
            {{ data.value.status === 'SUCCESS' ? $t('sms.success') : $t('sms.failed') }}
          </b-badge>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  name: "AdminEmails",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    TitleBar
  },
  mixins: [ResourceUtils],
  data() {
    return {
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      sortable: {
        order: "createdAt",
        sort: "desc",
      },
      columns: [
        {
          label: this.$t('sms.smsTemplate'),
          field: "templateName",
          sortable: true,
        },
        {
          label: this.$t('sms.createdAt'),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t('sms.phone'),
          field: "phone",
          sortable: true,
        },
        {
          label: this.$t('sms.status'),
          field: "status",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage
      };
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Sms.getCollection({params}, 'sms_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    setSort(offset, limit, order, sort) {
      this.sortable.sort = sort;
      this.sortable.order = order;

      this.load();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    statusClass(status) {
      let className = 'primary'
      if (status === 'SUCCESS') {
        className = 'success'
      }
      if (status === 'FAILED') {
        className = 'danger'
      }
      return className
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

